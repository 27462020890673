import thumbnail from './withModules.png';
import Settings from 'cccisd-laravel-assignment/dist/components/navigations/withModules/Settings';
import Mediator from './Mediator';

export default {
    handle: 'module_mkm',
    label: 'MKM Modules',
    thumbnail,
    settingsComponent: Settings,
    mediatorComponent: Mediator,
};
