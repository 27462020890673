import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import { Field, CccisdInput, CccisdFieldWrapper } from 'cccisd-formik';
import _get from 'lodash/get';
import DatePicker from 'cccisd-datepicker';
import format from 'date-fns/format';

const Register = () => {
    const Appdefs = window.cccisd.appDefs;
    const emailLabel = _get(Appdefs, 'app.selfRegistration.labels.email', 'Parent/Guardian Email:');
    const birthdateLabel = _get(Appdefs, 'app.selfRegistration.labels.birthdate', 'Child Birth Month/Year (MM/YYYY):');

    const registerLoginProps = {
        registerProps: {
            role: 'orgAdmin',
            showPasswordFields: true,
            hasSeparateUsername: true,
            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                if (!values.birthdate) {
                    errors.birthdate = 'Birth Date is required.';
                } else if (values.birthdate.length <= 7) {
                    errors.birthdate = 'Birth Date formatting is incorrect';
                }
                return errors;
            },
            renderAllFields: props => {
                const firstName = props.defaultFields.firstName;
                const lastName = props.defaultFields.lastName;
                const username = props.defaultFields.username;

                const password = props.defaultFields.password;
                const repeatPassword = props.defaultFields.repeatPassword;
                const termsAndConds = props.defaultFields.termsAndConds;

                const submitButton = props.defaultFields.submitButton;
                const termsAndCondsLinks = props.defaultFields.termsAndCondsLinks;
                const bottomInstructions = props.defaultFields.bottomInstructions;

                return (
                    <>
                        <h4 style={{ marginBottom: '25px' }}>Child Registration</h4>
                        <div className="alert alert-warning">
                            {
                                <>
                                    <span>Each child must have a unique username.</span>
                                    <br />
                                    <br />
                                    <span>
                                        You can have more than one username connected to your parent/guardian email
                                        address.
                                    </span>
                                </>
                            }
                        </div>
                        {firstName}
                        {lastName}
                        <Field
                            name="birthdate"
                            render={formikProps => {
                                return (
                                    <CccisdFieldWrapper {...formikProps}>
                                        <label>{birthdateLabel}</label>
                                        <div>
                                            <DatePicker
                                                className="form-control input-sm"
                                                showMonthYearPicker
                                                dateFormat="MM/yyyy"
                                                selected={formikProps.field.value}
                                                // handle changes to birthday value so that it is formatted correctly
                                                onChange={date => {
                                                    let value = date ? format(date, 'YYYY-MM') : null;
                                                    const formattedValue =
                                                        value && value.length === 7 ? value + '-01' : value;
                                                    formikProps.form.setFieldValue(
                                                        formikProps.field.name,
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </CccisdFieldWrapper>
                                );
                            }}
                        />
                        <Field
                            name="email"
                            autoComplete="off"
                            component={CccisdInput}
                            label={emailLabel}
                            type="email"
                        />
                        {username}
                        {password}
                        {repeatPassword}
                        {termsAndConds}
                        {submitButton(props.formikBag)}
                        {termsAndCondsLinks}
                        {bottomInstructions}
                    </>
                );
            },
        },
        loginProps: { title: 'Login' },
    };
    return <RegisterLoginPage {...registerLoginProps} />;
};

export default Register;
