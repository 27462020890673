import React, { useEffect, useState } from 'react';
import { Redirect } from 'cccisd-react-router';
import birthdayQuery from './birthdayQuery.graphql';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import moment from 'moment';

var Fortress = window.cccisd.fortress;

const AgeRedirect = props => {
    const [age, setAge] = useState(0);

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;
        const pawnHash = Fortress.user.acting.random_hash;

        // get user survey answers
        const getBirthdate = async () => {
            const result = await client.query({
                query: birthdayQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                    pawnHash,
                },
            });

            let birthdate = {};
            birthdate = result.data.roles.anyRoleList[0].fields.birthdate;

            var calc = parseInt(moment().diff(birthdate, 'years', true), 10);

            if (calc <= 0) {
                setAge(1);
                return;
            }

            setAge(calc);
        };

        getBirthdate();
    }, []);

    if (age === 0) {
        return <Loader loading />;
    }
    return age >= 10 ? <Redirect to="/survey/profilebuilder2" /> : <Redirect to="/survey/profilebuilder1" />;
};

export default AgeRedirect;
