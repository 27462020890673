import React from 'react';
import { Report } from 'cccisd-laravel-report';
import MKMReport from '../Report';

const Component = props => {
    let isInEditor = window.location.href.includes('/appdefs/');
    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report downloadFilename="MKM_Profile">
                <MKMReport {...props} isInEditor={isInEditor} />
            </Report>
        </div>
    );
};

export default Component;
