import React, { useState, useEffect } from 'react';
import PlatformWithModules from 'cccisd-laravel-assignment/dist/components/navigations/withModules/Mediator';
import Modal from 'cccisd-modal';
import MKMReport from '../../../vendor/reports/reportTemplates/mkmReport';
import { ForcedPlayerSettingsContext } from 'cccisd-laravel-flow';
import userChoicesQuery from '../user_choices_query.graphql';
import { client } from 'cccisd-apollo';

const appDefs = window.cccisd.appDefs;

const WithModulesMediator = props => {
    const [isSurveyBeingPlayed, setIsSurveyBeingPlayed] = useState(false);
    const [isUsePlayerContext, setIsUsePlayerContext] = useState(false);
    const [selectedPlayerSetings, setSelectedPlayerSettings] = useState({});
    const { actingPawnId, actingPawnHash } = props;

    useEffect(() => {
        // get metrics (assistant/custom avatar choices, and background selections)
        const getMetricsData = async () => {
            if (!isSurveyBeingPlayed) {
                return;
            }

            const result = await client.query({
                query: userChoicesQuery,
                fetchPolicy: 'network-only',
                variables: { pawnId: actingPawnId, pawnHash: actingPawnHash },
            });

            const metricsDataList = result.data.roles.anyRole.assignmentProgressList;
            if (!metricsDataList || !metricsDataList.length) {
                setSelectedPlayerSettings({});
                return;
            }

            let dataToUse = null;
            metricsDataList.forEach(assignmentProgress => {
                const handle = assignmentProgress.assignmentHandle;
                if (!['ages59', 'ages1014'].includes(handle)) {
                    return;
                }

                // already grabbed answers from 10-14 surveys
                if (dataToUse && handle === 'ages59') {
                    return;
                }

                if (
                    assignmentProgress?.devTags?.assistant_choice ||
                    assignmentProgress?.devTags?.background_choice ||
                    assignmentProgress?.devTags?.custom_avatar_urls
                ) {
                    dataToUse = assignmentProgress?.devTags;
                }
            });

            if (!dataToUse) {
                setSelectedPlayerSettings({});
                return;
            }

            const newPlayerSettings = {};
            let assistantMetric = dataToUse.assistant_choice;
            let backgroundMetric = dataToUse.background_choice;
            let customAvatarMetric = JSON.parse(dataToUse.custom_avatar_urls);

            if (customAvatarMetric) {
                newPlayerSettings.assistantAvatar = JSON.parse(
                    decodeURIComponent(customAvatarMetric.default_spritesheet).split('choices=')[1].split('&hash=')[0]
                );
            }

            if (assistantMetric) {
                assistantMetric = assistantMetric.toLowerCase();
                switch (assistantMetric) {
                    case 'buck':
                        newPlayerSettings.assistant = 'buck';
                        break;
                    case 'kat':
                        newPlayerSettings.assistant = 'cat';
                        break;
                    case 'frankie':
                        newPlayerSettings.assistant = 'frankie';
                        break;
                    case 'ollie':
                        newPlayerSettings.assistant = 'ollieStandard';
                        break;
                    case 'robot':
                        newPlayerSettings.assistant = 'robotboy';
                        break;
                    case 'avatar':
                        newPlayerSettings.assistant = 'avatar';
                        break;
                    default:
                        newPlayerSettings.assistant = '';
                }
            }

            if (backgroundMetric) {
                backgroundMetric = backgroundMetric.toLowerCase();
                let customBackgroundURL = '';
                switch (backgroundMetric) {
                    case 'blueclassroom':
                        backgroundMetric = 'uploadedImage';
                        customBackgroundURL =
                            'https://dm0gz550769cd.cloudfront.net/mkm/75/75fe4c8e7dc524a25ef8ae17bacfd5ff.png';
                        break;
                    case 'brownclassroom':
                        backgroundMetric = 'brownclassroom';
                        break;
                    case 'park':
                        backgroundMetric = 'uploadedImage';
                        customBackgroundURL =
                            'https://dm0gz550769cd.cloudfront.net/mkm/55/5520b95e08c461bdeecdfb847562a3f0.png';
                        break;
                    case 'basketballcourt':
                        backgroundMetric = 'uploadedImage';
                        customBackgroundURL =
                            'https://dm0gz550769cd.cloudfront.net/mkm/07/0705ba70aa8ffd7655bb89e8b7d6711b.png';
                        break;
                    case 'city':
                        backgroundMetric = 'uploadedImage';
                        customBackgroundURL =
                            'https://dm0gz550769cd.cloudfront.net/mkm/3d/3df200f489e7a1a04daa452a89048f04.png';
                        break;
                    case 'playground':
                        backgroundMetric = 'uploadedImage';
                        customBackgroundURL =
                            'https://dm0gz550769cd.cloudfront.net/mkm/04/04c5720d47542bfef06976795de9a38b.png';
                        break;
                    default:
                        backgroundMetric = '';
                        customBackgroundURL = '';
                }
                newPlayerSettings.background = backgroundMetric;
                newPlayerSettings.backgroundImage = {
                    url: customBackgroundURL,
                };
            }

            setSelectedPlayerSettings(newPlayerSettings);
        };

        getMetricsData();
    }, [isSurveyBeingPlayed]);

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;

        const mkmReportSettings = reportList.find(r => r.reportTemplateHandle === 'mkmReport');
        return mkmReportSettings.reportTemplateSettings;
    };

    return (
        <div>
            <div className="alert alert-warning" hidden={isSurveyBeingPlayed}>
                <div style={{ display: 'grid', gridAutoFlow: 'column', columnGap: '55px' }}>
                    <span style={{ color: '#644F30' }}>
                        As you and your child fill out the Profile Builder below, you’re making an MKM profile that you
                        can share with your child’s new teachers. At any point, click See Profile to view your profile
                        as it’s being built!
                        <br />
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '18%' }}>
                        <Modal
                            trigger={
                                <button type="button" className="btn btn-primary">
                                    See Profile
                                </button>
                            }
                            title="MKM Profile"
                            size="large"
                        >
                            <MKMReport.player settings={getReportSettings()} />
                        </Modal>
                    </div>
                </div>
            </div>
            <ForcedPlayerSettingsContext.Provider
                value={isSurveyBeingPlayed && isUsePlayerContext ? selectedPlayerSetings : {}}
            >
                <PlatformWithModules
                    {...props}
                    onPlayerShow={val => {
                        setIsSurveyBeingPlayed(true);
                        if (
                            ['survey7', 'survey8', 'survey9', 'survey24', 'survey25', 'survey26'].includes(
                                val.currentFlowProps.handle
                            )
                        ) {
                            setIsUsePlayerContext(true);
                        }
                    }}
                    onPlayerHide={() => {
                        setIsSurveyBeingPlayed(false);
                        setIsUsePlayerContext(false);
                    }}
                />
            </ForcedPlayerSettingsContext.Provider>
        </div>
    );
};

export default WithModulesMediator;
